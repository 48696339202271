import React, {Component} from 'react';
import { graphql, Link } from "gatsby"
import Layout from '../components/layout';
import BlogSidebar from '../components/blog-sidebar';
import CatoButton from '../components/cato-button';
import Anon from '../assets/images/anon.png';
import {htmlDecode} from "../common/utils";
import {IS_MOBILE} from "../common/consts";
import CatoImg from "../components/cato-image/index";
import StructuredData from '../components/structured-data';
import Award from '../assets/images/blog/Top-100-Networking-Blogs-Cato-Networks.png';
import Dummies from '../assets/images/blog/640x642-blog-side-SASE-for-dummies_new.png';
import '../assets/styles/pages/_blog.scss';
import '../assets/styles/responsive/pages/_blog-mobile.scss';


// const entities = new XmlEntities();
// const htmlDecode = input => entities.decode(input);

const contentLinksTargetBlank = () => {
    const links = Array.from(document.querySelectorAll('div.content-wrapper div.content a'));
    const domain = window.location.hostname;

    if(links.length > 0) {
        links.forEach((link) => {
            const href = link.getAttribute('href');

            if(!href.includes(domain)) {
                link.setAttribute('target', '_blank');
                link.setAttribute('rel', 'noopener noreferrer');
            }
        })
    }
};

const previewBanners = [
    {
        "sidebarImage": {
            "altText": "",
            "localFile": {
                "publicURL": Award
            }
        },
        "sidebarLink": "https://blog.feedspot.com/networking_blogs/"
    },
    {
        "sidebarImage": {
            "altText": "SASE for dummies",
            "localFile": {
                "publicURL": Dummies
            }
        },
        "sidebarLink": "/resources/secure-access-service-edge-sase-for-dummies"
    }
]

class PostTemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false
        }
    }

    componentDidMount() {
        if (IS_MOBILE) {
            this.setState({isMobile: true})
        }

        contentLinksTargetBlank();
    }

    genBreadcrumbs = (postData) => {
        const {lobby, type} = this.props.pageContext;
        const lobbyTitle = lobby === 'SASE' ? 'Secure Access Service Edge (SASE)' : `Cato ${this.props.pageContext.lobby}`;
        return (
            <div className="bread-crumbs">
                <Link to="/">Home</Link>
                <span> > </span>
                <Link to={`/${type}/`}>{lobbyTitle}</Link>
                <span> > </span>
                <span>{this.props.pageContext.breadcrumb ? this.props.pageContext.breadcrumb : htmlDecode(postData.title)}</span>
            </div>
        )
    };

    authorImage = (author) => {
        const imgUrl = author.node.clientProfilePic
            ?
                author.node.clientProfilePic
            :
            null;

        return imgUrl;
    }

    genAuthor = (postData) => {
        if(this.props.pageContext.excludeAuthor) {
            return null;
        }
        const imgUrl = this.authorImage(postData.author);

        const img = <div className="img-wrapper">
            {postData && postData.author &&
            <img
                src={imgUrl ? imgUrl : Anon} alt={postData.author.nodename || ""}/>}
        </div>

        const text = <div className="text">
            {postData.author && postData.author.node && postData.author.node.name && <h3 className="name">{postData.author.node.name}</h3>}
            {postData.author && postData.author.node && postData.author.node.description && <p className="description">{htmlDecode(postData.author.node.description)}</p>}
        </div>

        return (
            <div className="author-bio">
                {img}
                {text}
            </div>
        )
    };

    genStructuredData = (postData) => {
        const {content} = postData;
        const contentForWordCount = htmlDecode(content.replace(/(<([^>]+)>)/ig,""));
        const wordCount = contentForWordCount.split(' ').length;

        return (
            <StructuredData
                type={'BlogPosting'}
                data={{
                    image: postData.featured_media ? postData.featuredImage.node.sourceUrl : null,
                    headline: encodeURI(postData.title),
                    wordcount: wordCount.toString(),
                    articleBody: contentForWordCount,
                    publisher:
                        {
                        '@type': 'Organization',
                        name: 'Cato Networks',
                        logo: {
                            '@type': 'ImageObject',
                            url: this.props.data.logoPage.structuredOrganizationData.organizationData.structuredLogo.sourceUrl
                        }
                    },
                    datePublished: postData.dataDate,
                    dateModified: postData.dataDate,
                    dateCreated: postData.dataDate,
                    author: {
                        '@type': 'Person',
                        name: postData.author.name
                    },
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                        url: `https://www.catonetworks.com${this.props.path}`
                    }

                }}
            />)

    }


    render() {

        const {data} = this.props;
        const postData = this.props.pageContext.blog ? data.wpPost : data.wpNewsItem;

        const {newsPosts, blogIntroExcerptParent} = postData;

        const breadcrumbs = this.genBreadcrumbs(postData);

        const authorTop = postData.author
            ?
            <div className={`author ${!this.props.pageContext.hideDate ? 'with-dot' : ''}`}>
                <img src={this.authorImage(postData.author)} alt=""/><span>{postData.author.node.name}</span>
            </div>
            :
            null;

        const authorBottom = postData.author ? this.genAuthor(postData) : null;
        const readMore = this.props.pageContext.news && newsPosts && newsPosts.readMoreUrl
            ?
            <CatoButton type="link" path={newsPosts.readMoreUrl} className="green " label="Read More" external={true}/>
            :
            null;
        //

        const sideBanners = process.env.GATSBY_MODE === 'preview'
            ?
            previewBanners
                :
            this.props.pageContext.blog
                ?
                data.postLobbyBanners.postLobbySidebars.sidebarBanners
                :
                data.newsLobbyBanners.postLobbySidebars.sidebarBanners

        const relatedTopics = this.props.pageContext.blog && this.props.data.postLobbyBanners && this.props.data.postLobbyBanners.relatedTopicsLinksParent.relatedTopicsLinks ? {
            title: this.props.data.postLobbyBanners.relatedTopicsLinksParent.relatedTopicsHeadline,
            links: this.props.data.postLobbyBanners.relatedTopicsLinksParent.relatedTopicsLinks.map(item => ({displayName: item.title, path: item.link}))
        } : null;

        // const stickyBanner = !acf.show_sticky_banner ? false : {
        //     label: acf.sticky_banner_label,
        //     link: acf.sticky_banner_link,
        //     golden_label: acf.golden_button_text,
        //     golden_link: acf.golden_button_link,
        // };

        const structuredData = this.props.pageContext.blog && data.logoPage ? this.genStructuredData(postData) : null;

        const structBreadcrumbs = [
            {title: `Cato ${this.props.pageContext.lobby}`, path: `/${this.props.pageContext.type}`},
            {title: postData.title, path: `${this.props.path}`}
        ];

        return (
            <Layout
                className="blog-post post-inner"
                lang={this.props.pageContext.lang}
                whiteMenu={true}
                yoastData={{...postData.seo, canonical: `${this.props.location.pathname}${this.props.location.pathname[this.props.location.pathname.length - 1] === '/' ? '' : '/'}`} || null}
                seoTitle={postData.pageSeoTitle && postData.pageSeoTitle.pageSeoTitle ? `${postData.pageSeoTitle.pageSeoTitle}` : `${postData.title}`}
                title={postData.title}
                breadcrumbs={structBreadcrumbs}
                seoPath={this.props.location.pathname}
                wpid={postData.databaseId}
            >
                {structuredData}
                <div className="content-wrapper">
                    {breadcrumbs}
                    <article className="blog-post">
                        <h1 className="entry-title" style={ this.props.pageContext.hideDate ? {marginBottom: '25px'} : {}} dangerouslySetInnerHTML={{__html: postData.title}}/>
                        <div className="date-author-wrap">
                            {authorTop}
                            {!this.props.pageContext.hideDate && <span className="date">{postData.date}</span>}
                        </div>
                        {blogIntroExcerptParent && blogIntroExcerptParent.postIntroExcerpt && <div className="intro-excerpt" dangerouslySetInnerHTML={{__html: blogIntroExcerptParent.postIntroExcerpt}}/>}
                        {postData.featuredImage &&
                        <div className="featured-media">
                            <CatoImg img={this.state.isMobile ? postData.featuredImageMobile.node : postData.featuredImage.node}/>
                            {/*<img src={postData.featured_media.source_url} alt={postData.featured_media.alt_text || ""}/>*/}
                        </div>
                    }
                        {postData.newsPosts && postData.newsPosts.companyName && <span className="company">{postData.newsPosts.companyName}</span>}
                    <div className="content" dangerouslySetInnerHTML={{__html: postData.content}} />
                    {readMore}
                        {authorBottom}
                        {this.props.pageContext.blog && <div className="trd-ph-embedded" data-group="recommend"/>}
                </article>
                <BlogSidebar
                    banners={sideBanners}
                    location={this.props.location}
                    relatedTopics={relatedTopics}
                />
                </div>
            </Layout>
        )
    }
}

export default PostTemplate;

export const postQuery = graphql`
    query currentPostQuery($id: String!, $blog: Boolean!, $news: Boolean!) {
        wpPost(id: { eq: $id }) @include(if: $blog){
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM D, YYYY")
            dataDate: date(formatString: "DD-MM-YYYY")
            path: uri
            pageSeoTitle {
                pageSeoTitle
            }
            blogIntroExcerptParent {
                postIntroExcerpt
            }
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 423, quality: 90) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                        publicURL
                    }
                    sourceUrl
                    altText
                }
            }
            featuredImageMobile: featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 250) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                        publicURL
                    }
                    sourceUrl
                    altText
                }
            }
            author {
                node {
                    name
                    description
                    clientProfilePic
                    avatar {
                        url
                    }
                }
            }
           
            ...YoastPostData
        }
        wpNewsItem(id: { eq: $id }) @include(if: $news){
            title
            content
            slug
            id
            date(formatString: "MMMM D, YYYY")
            path: uri
            pageSeoTitle {
                pageSeoTitle
            }
            newsPosts {
                readMoreUrl
                companyName
            }
            ...YoastNewsData
        }
        postLobbyBanners: wpPage(templateFileName: {eq: "post-lobby.php"}){
            id
            postLobbySidebars {
                sidebarBanners {
                    sidebarImage {
                        altText
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 288, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                            publicURL
                        }
                    }
                    sidebarLink
                }
            }
            relatedTopicsLinksParent {
                relatedTopicsHeadline
                relatedTopicsLinks {
                    title
                    link
                }
            }
        }
        newsLobbyBanners: wpPage(templateFileName: {eq: "news-lobby.php"}){
            id
            postLobbySidebars {
                sidebarBanners {
                    sidebarImage {
                        altText
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 288, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                            publicURL
                        }
                    }
                    sidebarLink
                }
            }
        }
        site {
            id
            siteMetadata {
                title
            }
        }
        logoPage: wpPage(databaseId: { eq: 12 }) {
            id
            structuredOrganizationData {
                organizationData {
                    structuredLogo {
                        sourceUrl
                    }
                }
            }
        }
    }
`
